@import url("https://fonts.googleapis.com/css?family=Poppins");

.App {
  display: flex;
  flex-direction: column;
  height: 100vh;
  font-family: "Poppins", sans-serif;
}

.fill {
  display: flex;
  flex-direction: column;
  justify-content: top;
  flex: 1;
  max-width: 1500px;
  margin-left: auto;
  margin-right: auto;
}

.sessionButton {
  padding-left: 13px;
  padding-right: 13px;
  padding-top: 8px;
  padding-bottom: 8px;
  background-color: black;
  border-radius: 10px;
  cursor: pointer;
  color: white;
  font-weight: bold;
  font-size: 17px;
}

.link {
  color: #4f4f4f;
}

a {
  color: #4f4f4f;
}

a:hover {
  color: orange;
  transition: all 0.15s ease-in;
}

.link:hover {
  color: orange;
}

.navLink {
  color: rgba(255, 255, 255, 0.55);
}

.navLink:hover {
  color: orange;
}

.sidenav {
  height: 100%;
  width: 25%;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: #232323;
  transition: 0.5s ease;
  overflow-x: hidden;
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
